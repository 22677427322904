import {Component, Input} from '@angular/core';
import {SECTION_LIST_DISPLAY} from "../../common/nav-menu-list";
import {WEBSITE_SECTION_TYPE} from "../../common/website-model/website-section.model";
import {SkillAttributes} from "../../common/website-model/skill-out-dto.model";
import {DateFormatter, DateUtil} from "../../../../common/util/date.util";
import {WebsiteSectionCardComponent} from "../../website-section-card/website-section-card.component";

@Component({
  selector: 'app-w-skill',
  standalone: true,
    imports: [
        WebsiteSectionCardComponent
    ],
  templateUrl: './w-skill.component.html',
  styleUrl: './w-skill.component.scss'
})
export class WSkillComponent {
  @Input() skillAttributes: SkillAttributes;
  SECTION_LIST_DISPLAY = SECTION_LIST_DISPLAY;
  WEBSITE_SECTION_TYPE = WEBSITE_SECTION_TYPE;
  DateFormatter = DateFormatter;
  DateUtil = DateUtil;

  constructor() {
  }
}
