import {Component} from '@angular/core';
import {ResumeComponent} from "../resume/resume.component";

@Component({
    selector: 'tal-resume-showcase',
    imports: [
        ResumeComponent
    ],
    templateUrl: './resume-showcase.component.html',
    styleUrl: './resume-showcase.component.scss'
})
export class ResumeShowcaseComponent {

}
