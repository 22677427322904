import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {PoweredByFooterComponent} from "../../../components/powered-by-footer/powered-by-footer.component";
import {LibButtonComponent} from "../../../library/button/lib-button/lib-button.component";
import {ROUTE} from "../../../common/route-constants/route";
import {Router} from "@angular/router";

@Component({
    selector: 'tal-no-job-found',
    standalone: true,
    imports: [
        PoweredByFooterComponent,
        LibButtonComponent
    ],
    templateUrl: './no-job-found.component.html',
    styleUrl: './no-job-found.component.scss'
})
export class NoJobFoundComponent implements OnChanges {

    @Input() urlHandle: string;

    constructor(private router: Router) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.['urlHandle']?.currentValue) {
            this.urlHandle = changes?.['urlHandle']?.currentValue;
        }
    }

    navigateToCareerPage(): void {
        this.router.navigate([ROUTE.EMPLOYER, ROUTE.COMPANY, this.urlHandle]);
    }
}
