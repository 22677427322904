export class MetaUtil {
    private static metaUtil: MetaUtil;

    // NOTE: Method to get the single instance of the class
    public static getInstance(): MetaUtil {
        if (!MetaUtil.metaUtil) {
            MetaUtil.metaUtil = new MetaUtil();
        }
        return MetaUtil.metaUtil;
    }

    getMetaTags(key: string) {
        switch (key) {
            case 'RESUME':
                return {
                    title: '‍🚀 Standout by Talenlio - The leading AI Resume Builder trusted by 2,393,547 🚀',
                    description: 'Talenlio is the new generation AI-based resume platform for job seekers that uses AI to automate every aspect of creating a standout resume—writing, editing, formatting, background verification and reviewing.',
                    image: 'https://storage.googleapis.com/talenlio/metatag/default-meta-image-resume.png',
                    url: 'https://talenlio.com'
                };
            case 'CAREER_SITE':
                return {
                    title: '‍💼 HireOS - The leading AI powered paperless hiring solution 🚀',
                    description: 'Talenlio is the new generation AI-based resume platform for job seekers that uses AI to automate every aspect of creating a standout resume—writing, editing, formatting, background verification and reviewing.',
                    image: 'https://storage.googleapis.com/talenlio/orgs/default-meta-image-career-site.png',
                    url: 'https://talenlio.com'
                };
            default:
                return {
                    title: '👩‍🚀 Talenlio - A place to showcase talents and uncover opportunities 🚀',
                    description: 'Talenlio is the new generation AI-based resume platform for job seekers that uses AI to automate every aspect of creating a standout resume—writing, editing, formatting, background verification and reviewing.',
                    image: 'https://storage.googleapis.com/talenlio/metatag/default-meta-image.png',
                    url: 'https://talenlio.com'
                };
        }
    }
}
