import {Component, OnInit} from '@angular/core';
import {CurrencyPipe, NgTemplateOutlet} from '@angular/common';
import {ROUTE} from '../../../common/route-constants/route';
import {ActivatedRoute, Router} from '@angular/router';
import {
    JobPostOutDto,
    LocationOutDto,
    PublicApiJobPostService,
    SALARY_FREQUENCY_DISPLAY
} from '../../service/public-api-job-post.service';
import {CURRENCY_CODE, CurrencyUtil} from '../../enum/currency.util';
import {PoweredByFooterComponent} from '../../../components/powered-by-footer/powered-by-footer.component';
import {LinkService} from '../../../common/util/link.service';
import {JOB_POST_STATUS, WORK_TYPE_DISPLAY} from '../../enum/company.model';
import {NoJobFoundComponent} from '../no-job-found/no-job-found.component';
import {LibProgressBarComponent} from '../../../library/loader/lib-progress-bar/lib-progress-bar.component';
import {MatTooltip} from '@angular/material/tooltip';
import {MetaTagService} from '../../../service/meta-tag.service';
import {FaviconService} from '../../../service/favicon.service';
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'tal-job-detail',
    standalone: true,
    imports: [
        PoweredByFooterComponent,
        NgTemplateOutlet,
        NoJobFoundComponent,
        LibProgressBarComponent,
        MatTooltip
    ],
    templateUrl: './job-detail.component.html',
    styleUrl: './job-detail.component.scss',
    providers: [CurrencyPipe]
})
export class JobDetailComponent implements OnInit {
    jobPostOutDto: JobPostOutDto;
    jobPostId: string;
    defaultCoverImage = 'assets/images/cover-background.png';
    defaultCompanyLogo = 'assets/images/default-logo.png';
    isJobStatusPublished = false;
    isLoading = false;
    urlHandle: string;
    jobPostLocations: string;
    protected readonly WORK_TYPE_DISPLAY = WORK_TYPE_DISPLAY;
    protected readonly SALARY_FREQUENCY_DISPLAY = SALARY_FREQUENCY_DISPLAY;

    constructor(private currencyPipe: CurrencyPipe,
                private publicApiJobPostService: PublicApiJobPostService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private metaTagService: MetaTagService,
                private faviconService: FaviconService,
                private linkService: LinkService) {
        this.setJobPostId();
    }

    ngOnInit(): void {
        this.getJobPost();
    }

    openWebsite(): void {
        this.linkService.openLinkInNewTab(this.jobPostOutDto?.organizationMinOutDto?.websiteLink);
    }

    getSalary(salary: string, currency: CURRENCY_CODE): string {
        return currency
            ? CurrencyUtil.getFormattedCurrency(salary, CURRENCY_CODE[currency], this.currencyPipe, '1.0-0')
            : salary;
    }

    goToAllJobs(): void {
        this.router.navigateByUrl(ROUTE.EMPLOYER + ROUTE.SLASH + ROUTE.COMPANY + ROUTE.SLASH + this.jobPostOutDto?.organizationMinOutDto?.urlHandle);
    }

    applyJob(): void {
        window.open(environment.standoutBaseAppUrl + ROUTE.SLASH + ROUTE.PUBLIC + ROUTE.SLASH + ROUTE.JOBS + ROUTE.SLASH + this.jobPostOutDto?.id + ROUTE.SLASH + ROUTE.APPLY + '?step=account-information', '_blank');
    }

    isVisibleNonZeroState(): boolean {
        return this.jobPostOutDto?.skills?.length > 0 ||
            this.jobPostOutDto?.description !== null ||
            this.jobPostOutDto?.responsibilities !== null ||
            this.jobPostOutDto?.minSalary !== null ||
            this.jobPostOutDto?.degrees?.length > 0 ||
            this.jobPostOutDto?.requirements !== null;
    }

    private setJobPostId(): void {
        this.activatedRoute.paramMap.subscribe(params => {
            this.jobPostId = params.get(ROUTE.JOB_POST_ID);
        });
    }

    private getJobPost(): void {
        this.isLoading = true;
        this.publicApiJobPostService.find(this.jobPostId).subscribe(jobPostOutDto => {
            this.urlHandle = jobPostOutDto?.organizationMinOutDto?.name;
            this.getCommaSeparatedCities(jobPostOutDto?.locationOutDtos);
            if (jobPostOutDto?.status === JOB_POST_STATUS.PUBLISHED) {
                this.jobPostOutDto = jobPostOutDto;
                this.metaTagService.addJobPostTags(jobPostOutDto);
                this.faviconService.setFavicon(jobPostOutDto?.organizationMinOutDto?.faviconUrl);
                this.isJobStatusPublished = true;
            } else {
                this.isJobStatusPublished = false;
            }
            this.isLoading = false;
        });
    }

    private getCommaSeparatedCities(locationOutDtos: LocationOutDto[]): void {
        this.jobPostLocations = locationOutDtos.map(locationOutDto => locationOutDto?.city).join(', ');
    }
}
