<div class="w-full">
  <div class="header-section flex flex-row p-6 sticky top-0 z-10 justify-between items-center">
    <div class="heading-section">
      <div class="title font-normal text-sm text-slate-400 capitalize truncate title-width">Apply</div>
      <div
        class="sub-title font-normal text-lg text-slate-1000 capitalize truncate title-width mt-5">{{ jobPostTitle }}
      </div>
    </div>
    <div class="close-button ti ti-letter-x text-2xl cursor-pointer text-blue-2000" (click)="closeDialog()"></div>
  </div>
  <div mat-dialog-content>
    <div class="main-section">
      <div class="default-image m-auto">
        <img class="w-full" src="assets/images/job-post-apply.svg" alt="" srcset="">
      </div>
      <div class="text-blue-1000 text-lg font-medium text-center mt-5 title">
        {{ jobPostTitle }} | {{ jobPostOutDto?.organizationMinOutDto?.name }}
      </div>
      <div class="text-blue-2000 text-center mt-5">
        Apply in just 7 minutes using Talenlio
      </div>
    </div>
    <div class="cta-container flex items-center gap-4 p-4 mt-5">
      <div class="login-cta w-full flex cursor-pointer items-center place-content-center cta-button"
           style="background: var(--color-primary-light);color: var(--color-black-light)"
           (click)="navigateToLoginPage()">
        Already have an account? Log in
      </div>
      <div class="login-cta w-full flex cursor-pointer items-center place-content-center cta-button"
           (click)="navigateToSignUpPage()">
        Sign up and Apply
      </div>
    </div>
  </div>
</div>
