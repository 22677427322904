import {Injectable} from '@angular/core';
import {HttpClientWrapperService} from "../../service/http-client-wrapper.service";
import {ATTACHMENT_TYPE, PLACEMENT_DRIVE_TYPE} from "../../employer/enum/company.model";
import {Observable} from "rxjs";
import {PLACEMENT_DRIVE_STATUS} from "./placement-drive.model";
import {JobPostOutDto} from "../../employer/service/public-api-job-post.service";

@Injectable({
    providedIn: 'root'
})
export class PlacementDriveService {
    private publicUrl = "/public";
    private universityUrl = "/university";
    private placementDriveUrl = "/placementDrives";

    constructor(private httpClientWrapperService: HttpClientWrapperService) {
    }

    findPublicDriveJobs(universityId: string, driveId: string): Observable<PublicApiUniversityHasDriveOutDto> {
        return this.httpClientWrapperService.get<PublicApiUniversityHasDriveOutDto>(`${this.getUrl(universityId)}/${driveId}`);
    }

    findAll(universityId: string): Observable<PublicApiUniversityHasDriveOutDto[]> {
        return this.httpClientWrapperService.get<PublicApiUniversityHasDriveOutDto[]>(this.getUrl(universityId));
    }

    private getUrl(universityId: string): string {
        return `${this.publicUrl}${this.universityUrl}/${universityId}${this.placementDriveUrl}`;
    }
}

export class PublicApiUniversityHasDriveOutDto {
    id: string;
    name: string;
    fromDate: string;
    toDate: string;
    startTime: string;
    endTime: string;
    address: string;
    openForAll: Boolean;
    placementDriveType: PLACEMENT_DRIVE_TYPE;
    placementDriveStatus: PLACEMENT_DRIVE_STATUS;
    placementDriveHasJobOutDtos: DriveHasJobOutDto[];
    universityLogoOutDto: AttachmentOutDto;
    universityCoverPictureOutDto: AttachmentOutDto;
}

export class DriveHasJobOutDto {
    id: string;
    jobPostOutDto: JobPostOutDto;
}

export class AttachmentOutDto {
    id: string;
    path: string;
    fileName: string;
    url: string;
    attachmentType: ATTACHMENT_TYPE;
}
