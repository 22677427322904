@if (driveHasJobOutDto) {
  <div class="job-card-container cursor-pointer mb-2">
    <div class="job-card-content">
      <div class="title-container flex justify-between items-center">
        <div class="logo-container flex gap-4 items-center">
          <div class="logo flex items-center gap-4 overflow-hidden">
            <img class="w-14 h-14 rounded-lg"
                 [src]="driveHasJobOutDto?.jobPostOutDto?.organizationMinOutDto?.logoUrl || defaultCompanyLogo"
                 alt="" srcset="">
          </div>
          <div>
            <div class="title text-base font-medium leading-6">
              {{ driveHasJobOutDto?.jobPostOutDto?.jobTitle || '--' }}
            </div>
            <div class="company-link mt-1" (click)="visitWebsite()">
              {{ driveHasJobOutDto?.jobPostOutDto?.organizationMinOutDto?.name }} <span
              class="ti ti-arrow-up-right arrow-icon"></span></div>
          </div>
        </div>
      </div>
      <div class="flex justify-between items-center outer-container">
        <div class="location-container mt-8">
          <div class="icon-container flex items-center gap-2 text-sm font-normal leading-4">
            <div class="ti ti-map-pin"></div>
            <div class="icon-title">Location</div>
          </div>
          <div class="location-title truncate text-sm font-medium leading-5 mt-2">
            {{ driveHasJobOutDto?.jobPostOutDto?.locationOutDtos || '--' }}
          </div>
        </div>
        <div class="level-container  mt-8">
          <div class="icon-container flex items-center gap-2 text-sm font-normal leading-4">
            <div class="ti ti-chart-bar"></div>
            <div class="icon-title">Level</div>
          </div>
          <div class="location-title truncate text-sm font-medium leading-5 mt-2">
            {{ driveHasJobOutDto?.jobPostOutDto?.jobLevel || '--' }}
          </div>
        </div>

        <div class="job-type-container mt-8">
          <div class="icon-container flex items-center gap-2 text-sm font-normal leading-4">
            <div class="ti ti-clock-hour-4"></div>
            <div class="icon-title">Job Type</div>
          </div>
          <div class="location-title truncate text-sm font-medium leading-5 mt-2">
            {{ driveHasJobOutDto?.jobPostOutDto?.jobType || '--' }}
          </div>
        </div>

        <div class="department-container mt-8">
          <div class="icon-container flex items-center gap-2 text-sm font-normal leading-4">
            <div class="ti ti-briefcase"></div>
            <div class="icon-title">Department</div>
          </div>
          <div class="location-title truncate text-sm font-medium leading-5 mt-2">
            {{ driveHasJobOutDto?.jobPostOutDto?.department || '--' }}
          </div>
        </div>
      </div>
    </div>
    <div class="job-footer flex justify-between items-center p-2 px-4">
      <div class="text-xs font-medium leading-6 time-title">
        {{ publicApiUniversityHasDriveOutDto?.name }}
      </div>
      <div class="flex gap-2 items-center sub-heading">
        <div class="text-xs font-medium leading-6 time-title footer-font-size">
          @if (!isPlacementDriveJobExpired) {
            @if (publicApiUniversityHasDriveOutDto?.toDate) {
              <span>
                Expires at
                {{ publicApiUniversityHasDriveOutDto?.toDate | date: DateFormatter.DAY_MONTH_YEAR }}
            </span>
            }
          }
          @if (isPlacementDriveJobExpired) {
            <span class="expired">Expired</span>
          }
        </div>
        @if (!isPlacementDriveJobExpired && publicApiUniversityHasDriveOutDto?.toDate) {
          <div class="text-xs font-semibold time-title footer-font-size">
            •  {{ (remainingDays === 0) ? 'Few hours to go' : remainingDays + ' days remaining' }}
          </div>
        }
      </div>
    </div>
  </div>
}
