import { COVER_LETTER_THEME_TYPE } from "../model/cover-letter/cover-letter.model";
import { CoverLetterThemeModel } from "../model/cover-letter-theme.model";

export class CoverLetterThemeUtil {
    private readonly colorPrimary: string;
    private readonly colorTertiary: string;
    private readonly colorWhite: string;

    constructor() {
        this.colorPrimary = getComputedStyle(document.documentElement).getPropertyValue('--color-primary');
        this.colorTertiary = getComputedStyle(document.documentElement).getPropertyValue('--color-tertiary');
        this.colorWhite = getComputedStyle(document.documentElement).getPropertyValue('--color-white');
    }

    public getCoverLetterTheme(coverLetterThemeType: string): CoverLetterThemeModel {
        switch (coverLetterThemeType) {
            case COVER_LETTER_THEME_TYPE.SINGAPORE:
                return this.getSingaporeTheme();
            case COVER_LETTER_THEME_TYPE.AMSTERDAM:
                return this.getAmsterdamTheme();
            default:
                return this.getAmsterdamTheme();
        }
    }

    private getSingaporeTheme(): CoverLetterThemeModel {
        return {
            primaryColor: this.colorPrimary,
            secondaryColor: this.colorPrimary,
            ternaryColor: this.colorTertiary,
            quaternaryColor: this.colorWhite,
            footerImage: 'assets/cover-letter/theme-image/singapore-footer.svg',
        } as CoverLetterThemeModel;
    }

    private getAmsterdamTheme(): CoverLetterThemeModel {
        return {
            primaryColor: this.colorTertiary,
            secondaryColor: this.colorPrimary,
            ternaryColor: this.colorTertiary,
            quaternaryColor: this.colorWhite,
            footerImage: 'assets/cover-letter/theme-image/singapore-footer.svg',
        } as CoverLetterThemeModel;
    }
}
