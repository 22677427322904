import {Component, Input, OnInit} from '@angular/core';
import {DriveHasJobOutDto, PublicApiUniversityHasDriveOutDto} from "../service/placementDrive.service";
import {DateFormatter, DateUtil} from "../../common/util/date.util";
import {DatePipe} from "@angular/common";
import {LinkService} from "../../common/util/link.service";

@Component({
  selector: 'tal-job-card-deprecated',
  standalone: true,
  imports: [
    DatePipe
  ],
  templateUrl: './job-card-deprecated.component.html',
  styleUrl: './job-card-deprecated.component.scss'
})
export class JobCardDeprecatedComponent implements OnInit {

  @Input() driveHasJobOutDto: DriveHasJobOutDto;
  @Input() publicApiUniversityHasDriveOutDto: PublicApiUniversityHasDriveOutDto;
  @Input() isPlacementDriveJobExpired: boolean;
  @Input() defaultCompanyLogo: string;
  DateFormatter = DateFormatter;
  remainingDays: number;
  currentDate = new Date();

  constructor(private linkService: LinkService) {
  }

  ngOnInit(): void {
    this.setDate();
  }

  visitWebsite(): void {
      this.linkService.openLinkInNewTab(this.driveHasJobOutDto?.jobPostOutDto?.applicationLink);
  }

  private setDate(): void {
    const currentDate: string = this.currentDate.toISOString();
    this.remainingDays = DateUtil.dateDifference(this.publicApiUniversityHasDriveOutDto.toDate, currentDate);
  }
}
