import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Inject} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FaviconService {
    private renderer: Renderer2;

    constructor(
        rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    setFavicon(faviconUrl: string): void {
        const faviconElement = this.document.getElementById('dynamic-favicon') as HTMLLinkElement;

        if (faviconElement) {
            this.renderer.setAttribute(faviconElement, 'href', faviconUrl);
        }
    }
}
