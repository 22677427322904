import {Component, Input} from '@angular/core';
import {OrganizationOutDto} from "../../service/public-api-organization.service";

@Component({
    selector: 'app-about-us',
    standalone: true,
    imports: [],
    templateUrl: './about-us.component.html',
    styleUrl: './about-us.component.scss'
})
export class AboutUsComponent {
    @Input() organizationOutDto: OrganizationOutDto;
    @Input() companyUrl: string;
}
