@if (organizationOutDto?.careerSite?.summary) {
    <div class="abouts-section">
        <div class="about-us-content text-sm leading-8 my-5 font-poppins"
             [innerHTML]="organizationOutDto?.careerSite?.summary">
        </div>
    </div>
} @else {
    <div class="grid place-content-center text-center py-4">
        <img class="ml-auto mr-auto" src="assets/images/astronaut-logo.png" alt="">
        <h1 class="text-2xl font-medium leading-6 mt-5">No Company Details Available!!!</h1>
    </div>
}
