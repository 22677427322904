<div class="job-card-container cursor-pointer mb-2">
    <div class="job-card-content overflow-x-auto">
        <div class="title-container flex justify-between items-center">
            <div class="logo-container flex gap-4 items-center">
                <div class="logo flex items-center gap-4 overflow-hidden">
                    <img class="w-14 h-14 rounded-lg" [src]="jobCardModel?.logo" alt="" srcset="">
                </div>
                <div>
                    <div class="title text-base font-medium leading-6">
                        {{ jobCardModel?.title }}
                    </div>
                    @if (jobCardModel?.websiteLink) {
                        <div class="company-link mt-1" (click)="visitWebsite()">
                            Visit Website
                            <span class="ti ti-arrow-up-right arrow-icon">
              </span>
                        </div>
                    }
                </div>
            </div>
        </div>
        <div class="flex justify-between items-center outer-container">
            <div class="location-container mt-8">
                <div class="icon-container flex items-center gap-2 text-sm font-normal leading-4">
                    <div class="ti ti-map-pin"></div>
                    <div class="icon-title">Location</div>
                </div>
                <div class="location-title truncate text-sm font-medium leading-5 mt-2">
                    {{ jobCardModel?.location || '--' }}
                </div>
            </div>

            <div class="level-container mt-8">
                <div class="icon-container flex items-center gap-2 text-sm font-normal leading-4">
                    <div class="ti ti-chart-bar"></div>
                    <div class="icon-title">Level</div>
                </div>
                <div class="location-title truncate text-sm font-medium leading-5 mt-2">
                    {{ jobCardModel?.level || '--' }}
                </div>
            </div>
            @if (jobCardModel?.placementDriveType) {
                <div class="level-container mt-8">
                    <div class="icon-container flex items-center gap-2 text-sm font-normal leading-4">
                        <div class="ti ti-school text-base"></div>
                        <div class="icon-title">Placement Drive Type</div>
                    </div>
                    <div class="location-title truncate text-sm font-medium leading-5 mt-2">
                        {{ jobCardModel?.placementDriveType }}
                    </div>
                </div>
            }
            <div class="job-type-container mt-8">
                <div class="icon-container flex items-center gap-2 text-sm font-normal leading-4">
                    <div>
                        <div class="flex items-center gap-2">
                            <div class="ti ti-clock-hour-4"></div>
                            <div class="icon-title">Job Type</div>
                        </div>
                        <div class="location-title truncate text-sm font-medium leading-5 mt-2">
                            {{ jobCardModel?.jobType || '--' }}
                        </div>
                        @if (jobCardModel?.startDate) {
                            <div class="flex items-center gap-2">
                                <div class="ti ti-clock-hour-4"></div>
                                <div class="icon-title">Start Date</div>
                            </div>
                            <div class="location-title truncate text-sm font-medium leading-5 mt-2">
                                {{ jobCardModel?.startDate | date: DateFormatter.DAY_MONTH_YEAR }}
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div class="department-container mt-8">
                <div class="icon-container text-sm font-normal leading-4 flex">
                    @if (jobCardModel?.workType) {
                        <div class="flex flex-col justify-start">
                            <div class="flex items-center gap-2">
                                <div class="ti ti-clock-hour-4"></div>
                                <div class="icon-title">Work Type</div>
                            </div>
                            <div class="location-title truncate text-sm font-medium leading-5 mt-2">
                                {{ jobCardModel?.workType }}
                            </div>
                        </div>
                    }
                    @if (jobCardModel?.endDate) {
                        <div class="flex flex-col justify-start">
                            <div class="flex items-center gap-2">
                                <div class="ti ti-clock-hour-4"></div>
                                <div class="icon-title">End Date</div>
                            </div>
                            <div class="location-title truncate text-sm font-medium leading-5 mt-2">
                                {{ jobCardModel?.endDate | date: DateFormatter.DAY_MONTH_YEAR }}
                            </div>
                        </div>
                    }
                    @if (jobCardModel?.department) {
                        <div class="flex flex-col justify-start">
                            <div class="flex items-center gap-2">
                                <div class="ti ti-clock-hour-4"></div>
                                <div class="icon-title">Department</div>
                            </div>
                            <div class="location-title truncate text-sm font-medium leading-5 mt-2">
                                {{ jobCardModel?.department }}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
    <div class="job-footer flex justify-between items-center p-2 px-4"
         [ngClass]="{'hidden': !jobCardModel?.isViwJobSectionVisible}">
        @if (jobCardModel?.isPlacementDriveJobExpired) {
            <div class="text-sm color-primary font-medium w-full">
                <div class="expired flex place-content-end">Expired</div>
            </div>
        } @else {
            <div class="flex gap-2 justify-between items-center w-full sub-heading">
                <div class="text-xs font-medium flex gap-2 items-center leading-6 color-primary">
                    <div>View all jobs</div>
                    <div
                        class="bg-white rounded-xl all-job-count color-primary flex items-center h-5 w-5 place-content-center m-auto">
                        {{ jobCardModel?.allJobCount }}
                    </div>
                    <div class="ti ti-chevron-down text-base"></div>
                </div>
                <div class="flex gap-2 items-center remaining-days-container">
                    <div class="text-xs font-medium flex leading-6 time-title footer-font-size">
                        <span>Expires at {{ jobCardModel?.publicApiEmployerHasDriveOutDto?.toDate | date: DateFormatter.DAY_MONTH_YEAR }}</span>
                    </div>
                    <div class="text-xs font-semibold time-title footer-font-size">
                        •  {{ (jobCardModel?.remainingDays === 0) ? 'Few hours to go' : jobCardModel?.remainingDays + ' days remaining' }}
                    </div>
                </div>
            </div>
        }
    </div>
</div>
