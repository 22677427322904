import {Component, OnInit} from '@angular/core';
import {COMPANY_SIZE_DISPLAY} from '../enum/company.model';
import {JobComponent} from './job/job.component';
import {ActivatedRoute} from '@angular/router';
import {ROUTE} from '../../common/route-constants/route';
import {AboutUsComponent} from './about-us/about-us.component';
import {PoweredByFooterComponent} from '../../components/powered-by-footer/powered-by-footer.component';
import {MatTab, MatTabGroup} from '@angular/material/tabs';
import {MetaTagService} from '../../service/meta-tag.service';
import {FaviconService} from '../../service/favicon.service';
import {OrganizationOutDto, PublicApiOrganizationService} from "../service/public-api-organization.service";
import {LinkService} from "../../common/util/link.service";

@Component({
    selector: 'tal-company',
    standalone: true,
    imports: [
        JobComponent,
        AboutUsComponent,
        PoweredByFooterComponent,
        MatTabGroup,
        MatTab
    ],
    templateUrl: './company.component.html',
    styleUrl: './company.component.scss'
})
export class CompanyComponent implements OnInit {
    organizationOutDto: OrganizationOutDto;
    defaultCoverImage = 'assets/images/cover-background.png';
    companyLogo = 'assets/images/default-logo.png';
    companyUrl: string;
    protected readonly COMPANY_SIZE_DISPLAY = COMPANY_SIZE_DISPLAY;

    constructor(private activatedRoute: ActivatedRoute,
                private publicApiOrganizationService: PublicApiOrganizationService,
                private metaTagService: MetaTagService,
                private faviconService: FaviconService,
                private linkService: LinkService) {
    }

    ngOnInit(): void {
        this.setCompanyUrl();
        this.setCompanyOutDto();
    }

    openWebsite(): void {
        this.linkService.openLinkInNewTab(this.organizationOutDto?.websiteLink);
    }

    private setCompanyUrl(): void {
        this.activatedRoute.paramMap.subscribe(params => {
            const companyUrl = (params.get(ROUTE.URL_HANDLE));
            if (companyUrl) {
                this.companyUrl = companyUrl;
            }
        });
    }

    private setCompanyOutDto(): void {
        this.publicApiOrganizationService.findByUrlHandle(this.companyUrl)
            .subscribe(organizationOutDto => {
                this.organizationOutDto = organizationOutDto;
                this.metaTagService.addCareerSiteTags(organizationOutDto);
                this.faviconService.setFavicon(organizationOutDto?.urlHandle);
            });
    }
}
