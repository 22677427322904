import {ApplicationConfig, importProvidersFrom, inject, isDevMode, PLATFORM_ID} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {HttpClientModule, provideHttpClient, withFetch} from '@angular/common/http';
import {provideClientHydration} from '@angular/platform-browser';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {provideLottieOptions} from 'ngx-lottie';
import {provideServiceWorker} from '@angular/service-worker';
import {FIREBASE_PROVIDERS} from './common/constant/firebase.providers';
import {isPlatformBrowser} from '@angular/common';
import {LottiePlayer} from 'lottie-web';

export function lottiePlayerFactory(): LottiePlayer | null {
    const platformId = inject(PLATFORM_ID);
    if (isPlatformBrowser(platformId)) {
        return require('lottie-web');
    }
    return null;
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        importProvidersFrom(HttpClientModule),
        provideClientHydration(),
        provideAnimationsAsync(),
        provideHttpClient(withFetch()),
        FIREBASE_PROVIDERS,
        provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
        }),
        provideLottieOptions({
            player: lottiePlayerFactory,
        }),
        provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
        }),
    ]
};

