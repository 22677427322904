import {Component} from '@angular/core';
import {CONSTANTS} from "../../common/constant/constant";
import {LinkService} from "../../common/util/link.service";

@Component({
    selector: 'app-powered-by-footer',
    standalone: true,
    templateUrl: './powered-by-footer.component.html',
    styleUrl: './powered-by-footer.component.scss'
})
export class PoweredByFooterComponent {

    constructor(private linkService: LinkService) {
    }

    navigateToTalenlio(): void {
        this.linkService.openLinkInNewTab(CONSTANTS.TALENLIO_LINK);
    }
}
