import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ROUTE} from "../../../common/route-constants/route";
import {JobCardComponent, JobCardModel} from "../../../common/component/job-card/job-card.component";
import {WORK_TYPE_DISPLAY} from "../../enum/company.model";
import {
    LibCardSkeletonComponent,
    SKELETON_LAYOUT_TYPE
} from "../../../library/skeleton/lib-card-skeleton/lib-card-skeleton.component";
import {NgTemplateOutlet} from "@angular/common";
import {JobPostOutDto, LocationOutDto} from "../../service/public-api-job-post.service";
import {OrganizationOutDto, PublicApiOrganizationService} from "../../service/public-api-organization.service";

@Component({
    selector: 'app-job',
    standalone: true,
    imports: [
        JobCardComponent,
        LibCardSkeletonComponent,
        NgTemplateOutlet
    ],
    templateUrl: './job.component.html',
    styleUrl: './job.component.scss'
})
export class JobComponent implements OnInit {
    @Input() companyUrl: string;
    defaultCompanyLogo = "assets/images/default-logo.png";
    jobPostOutDtos: JobPostOutDto[];
    organizationOutDto: OrganizationOutDto;
    isZeroState: boolean;
    isLoading = false;
    protected readonly WORK_TYPE_DISPLAY = WORK_TYPE_DISPLAY;
    protected readonly SKELETON_LAYOUT_TYPE = SKELETON_LAYOUT_TYPE;

    constructor(private publicApiOrganizationService: PublicApiOrganizationService,
                private router: Router,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.setJobPostOutDto();
    }

    navigateToViewDetails(jobPostOutDto: JobPostOutDto): void {
        this.router.navigate([ROUTE.JOBS, jobPostOutDto?.id], {
            relativeTo: this.activatedRoute
        });
    }

    commaSeparatedCities(locationOutDtos: LocationOutDto[]): string {
        return locationOutDtos?.map(locationOutDto => locationOutDto?.city).join(', ');
    }

    setJobCardModel(jobPostOutDto: JobPostOutDto): JobCardModel {
        return {
            logo: jobPostOutDto?.organizationMinOutDto?.logoUrl ?? this.defaultCompanyLogo,
            title: jobPostOutDto?.jobTitle,
            companyLink: jobPostOutDto?.organizationMinOutDto?.websiteLink,
            companyName: jobPostOutDto?.organizationMinOutDto?.name,
            location: this.commaSeparatedCities(jobPostOutDto?.locationOutDtos),
            level: jobPostOutDto?.jobLevel,
            department: jobPostOutDto?.department,
            jobType: jobPostOutDto?.jobType,
            workType: WORK_TYPE_DISPLAY[jobPostOutDto?.workType],
            websiteLink: jobPostOutDto?.organizationMinOutDto?.websiteLink,
            isViwJobSectionVisible: false,
        } as JobCardModel;
    }

    private setJobPostOutDto(): void {
        this.isLoading = true;
        this.publicApiOrganizationService.find(this.companyUrl)
            .subscribe(organizationOutDto => {
                this.organizationOutDto = organizationOutDto;
                this.isLoading = false;
                this.jobPostOutDtos = organizationOutDto?.jobPostOutDtos;
                this.isZeroState = organizationOutDto?.jobPostOutDtos?.length === 0;
            });
    }
}
