import {Component, Input} from '@angular/core';
import {DateFormatter} from '../../../common/util/date.util';
import {DatePipe} from '@angular/common';
import {LinkService} from '../../../common/util/link.service';

@Component({
    selector: 'app-website-section-sub-card',
    standalone: true,
    imports: [
        DatePipe
    ],
    templateUrl: './website-section-sub-card.component.html',
    styleUrl: './website-section-sub-card.component.scss'
})
export class WebsiteSectionSubCardComponent {
    @Input() title: string;
    @Input() subTitle: string;
    @Input() startDate: string;
    @Input() endDate: string;
    @Input() present: string;
    @Input() location: string;
    @Input() dateDifference: string;
    @Input() description: string;
    @Input() url: string;
    DateFormatter = DateFormatter;

    constructor(private linkService: LinkService) {
    }

    openLink(): void {
        this.linkService.openLinkInNewTab(this.url);
    }
}
