<div class="detail-container overflow-auto bg-center bg-cover h-screen p-5"
     style="background-image: url('assets/resume/talenlio-background-image.png');">
    <div class="company-container p-2 m-auto">
        <div class="color-primary flex items-center gap-2 cursor-pointer" (click)="goToAllJobs()">
            <div class="ti ti-arrow-narrow-left"></div>
            <div class="cursor-pointer">See all jobs</div>
        </div>
        <div class="item-container flex gap-6 w-full mt-8">
            <div class="job-container h-max-content overflow-hidden rounded-2xl box-shadow">
                <div class="banner-container h-36">
                    <img
                        [src]="jobPostOutDto?.organizationMinOutDto?.coverPictureUrl || bannerImage"
                        class="w-full h-full"
                        alt="">
                </div>
                <div class="company-logo w-20 h-20 overflow-hidden m-auto box-shadow" style="margin-top: -30px">
                    <img [src]="jobPostOutDto?.organizationMinOutDto?.logoUrl|| defaultCompanyLogo"
                         alt=""
                         class="w-full h-full">
                </div>
                @if (jobPostOutDto?.jobTitle) {
                    <div class="company-title text-3xl font-medium mt-20 font-jost text-center career-site-font-family">
                        {{ jobPostOutDto?.jobTitle }}
                    </div>
                }
                <div class="job-detail-container overflow-hidden">
                    <div class="job-detail-card overflow-hidden">
                        <div class="location-section flex gap-5">
                            <div class="company">
                                <div class="company-content flex gap-2 items-center career-site-font-family">
                                    <div class="company-icon ti ti-briefcase"></div>
                                    <div class="section-title career-site-font-family">Company</div>
                                </div>
                                @if (jobPostOutDto?.organizationMinOutDto?.websiteLink) {
                                    <div class="website-link w-full truncate" (click)="visitWebsite()">
                                        Visit website
                                    </div>
                                }
                            </div>
                            <!--                                TODO : will add this later-->
                            <!--                            <div class="company">-->
                            <!--                                <div class="company-content flex gap-2 items-center career-site-font-family">-->
                            <!--                                    <div class="company-icon ti ti-map-pin"></div>-->
                            <!--                                    <div class="section-title">Location</div>-->
                            <!--                                </div>-->
                            <!--                                @if (jobPostOutDto?.locationOutDtos) {-->
                            <!--                                    <div class="company-type w-full truncate">-->
                            <!--                                        {{ jobPostOutDto?.locationOutDtos }}-->
                            <!--                                    </div>-->
                            <!--                                }-->
                            <!--                            </div>-->
                            <div class="company">
                                <div class="company-content flex gap-2 items-center career-site-font-family">
                                    <div class="company-icon  ti ti-chart-bar"></div>
                                    <div class="section-title">Experience</div>
                                </div>
                                @if (jobPostOutDto?.jobLevel) {
                                    <div class="company-type w-full truncate">
                                        {{ jobPostOutDto?.jobLevel || '--' }}
                                    </div>
                                }
                            </div>
                            <div class="company">
                                <div class="company-content flex gap-2 items-center career-site-font-family">
                                    <div class="company-icon ti ti-briefcase-2"></div>
                                    <div class="section-title career-site-font-family">Job Type</div>
                                </div>
                                @if (jobPostOutDto?.jobType) {
                                    <div class="company-type w-full truncate">
                                        {{ jobPostOutDto?.jobType || '--' }}
                                    </div>
                                }
                            </div>
                        </div>
                        @if (jobPostOutDto?.skills?.length > 0) {
                            <div class="skill-section">
                                <div class="skill-title">
                                    Skill Required
                                </div>
                                <div class="flex flex-wrap gap-3">
                                    @for (skill of jobPostOutDto?.skills; let index = $index; track index) {
                                        <div class="group-list mr-2 mt-2">
                                            <div
                                                class="container-title">{{ skill }}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        @if (jobPostOutDto?.description) {
                            <div class="about-section">
                                <div class="section-heading career-site-font-family">About the job</div>
                                <div class="about-paragraph career-site-font-family"
                                     [innerHTML]="jobPostOutDto?.description">
                                </div>
                            </div>
                        }
                        @if (jobPostOutDto?.responsibilities) {
                            <div class="about-section">
                                <div class="section-heading career-site-font-family">Responsibilities</div>
                                <div class="about-paragraph career-site-font-family"
                                     [innerHTML]="jobPostOutDto?.responsibilities">
                                </div>
                            </div>
                        }
                        @if (jobPostOutDto?.requirements) {
                            <div class="about-section">
                                <div class="section-heading career-site-font-family">Requirements</div>
                                <div class="about-paragraph career-site-font-family"
                                     [innerHTML]="jobPostOutDto?.requirements">
                                </div>
                            </div>
                        }
                        @if (jobPostOutDto?.minSalary) {
                            <div class="about-section">
                                <h1 class="section-heading career-site-font-family">
                                    {{
                                        jobPostOutDto?.salaryFrequency ?
                                            SALARY_FREQUENCY_DISPLAY[jobPostOutDto?.salaryFrequency] : 'Annual'
                                    }} Salary
                                </h1>
                                <div class="flex justify-start items-center">
                                    <div class="group-list mr-2 mt-2 flex">
                                        <div class="container-title mr-2">
                                            {{ getSalary(jobPostOutDto?.minSalary, jobPostOutDto?.currency) }}
                                        </div>
                                    </div>
                                    @if (jobPostOutDto?.maxSalary) {
                                        <span class="mt-2">-</span>
                                        <div class="group-list mr-2 mt-2 flex ml-2">
                                            <div class="container-title mr-2">
                                                {{ getSalary(jobPostOutDto?.maxSalary, jobPostOutDto?.currency) }}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            @if (jobPostOutDto?.degrees?.length > 0) {
                                <div class="about-section">
                                    <div class="section-heading">Preferred Degree</div>
                                    <div class="flex flex-wrap">
                                        @for (degree of jobPostOutDto?.degrees; let index = $index; track index) {
                                            <div class="group-list mr-2 mt-2">
                                                <div
                                                    class="container-title">{{ degree }}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        } @else {
                            <div class="p-4 w-full">
                                <img class="m-auto" src="assets/images/astronaut-logo.png" alt="" srcset="">
                                <div class="text-xl text-center mt-5 title">No Job detail are available</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div class="cta-container sticky top-0 h-max overflow-hidden rounded-2xl">
                <div class="apply-container p-6">
                    <div class="logo h-20 w-20 overflow-hidden">
                        <img
                            [src]="jobPostOutDto?.organizationMinOutDto?.logoUrl || defaultCompanyLogo"
                            alt=""
                            class="w-full h-full">
                    </div>
                    <div class="title text-2xl mt-5 career-site-font-family">Apply now</div>
                    <div class="sub-title career-site-font-family text-base mt-5">Please let company know that you found
                        this
                        position on our job board,
                        as that is a
                        great way to support us, so we can keep posting cool jobs every day.
                    </div>
                    <div
                        class="apply-cta cursor-pointer title text-base mt-2 p-6 rounded-full text-center career-site-font-family"
                        (click)="applyJob(jobPostOutDto)">Apply now
                    </div>
                </div>

                <div class="apply-container p-6 mt-5">
                    <div class="title text-2xl mt-5 career-site-font-family">Hire a resume writer ✍️</div>
                    <div class="sub-title career-site-font-family text-base mt-5 career-site-font-family">Put your best
                        foot
                        forward with your application.
                    </div>
                    <div
                        class="apply-cta-2 cursor-pointer title career-site-font-family text-base mt-2 p-6 rounded-full text-center">
                        Get a resume writer
                        🚀
                    </div>
                </div>
            </div>
            <div class="apply-jobs bottom-2 fixed w-full">
                <div
                    class="apply-cta w-11/12 ml-2 cursor-pointer title career-site-font-family text-base mt-2 p-6 rounded-full text-center">
                    Apply now
                </div>
            </div>
        </div>
    </div>
    <div class="footer-container mt-10">
        <app-powered-by-footer></app-powered-by-footer>
    </div>
</div>
