import {Injectable} from '@angular/core';
import {HttpClientWrapperService} from "../../service/http-client-wrapper.service";
import {JOB_POST_STATUS} from "../enum/company.model";
import {CURRENCY_CODE} from "../enum/currency.util";
import {Observable} from 'rxjs';
import {RequestParam} from "../../common/model/request-param";

@Injectable({
    providedIn: 'root'
})
export class PublicApiJobPostService {

    private publicJobPostApiUrl = '/public/jobPosts';

    constructor(private httpClientWrapperService: HttpClientWrapperService) {
    }

    search(organizationId: string): Observable<JobPostOutDto[]> {
        return this.httpClientWrapperService.get<JobPostOutDto[]>(`${this.publicJobPostApiUrl}`, this.getRequestParams(organizationId));
    }

    find(jobPostId: string): Observable<JobPostOutDto> {
        return this.httpClientWrapperService.get<JobPostOutDto>(`${this.publicJobPostApiUrl}/${jobPostId}`);
    }

    private getRequestParams(organizationId: string): RequestParam[] {
        return [{key: 'organizationId', value: organizationId}];
    }
}

export class JobPostCommonDto {
    workType: string;
    status: JOB_POST_STATUS;
    applicationLink: string;
    responsibilities: string;
    description: string;
    urlHandle: string;
    currency: CURRENCY_CODE;
    salaryFrequency: SALARY_FREQUENCY;
    jobFeatured: boolean;
    externalJob: boolean;
    publicJobId: boolean;
    jobTitle: string;
    requirements: string;
    minSalary?: string;
    maxSalary?: string;
    jobPostDate: string;
    publicJob?: boolean;
    autoRejectDays?: string;
    expectedResumeScore: number;
    department: string;
    jobType: string;
    jobLevel: string;
    skills: string[];
    degrees: string[];
}

export class JobPostOutDto extends JobPostCommonDto {
    id: string;
    organizationMinOutDto?: OrganizationMinOutDto;
    locationOutDtos?: LocationOutDto[];
    createdAt: string;
    lastModifiedAt: string;
}

export class OrganizationMinOutDto {
    id: string;
    name: string;
    organizationStatus: ORGANIZATION_STATUS;
    domain: string;
    logoUrl: string;
    coverPictureUrl: string;
    websiteLink: string;
    faviconUrl: string;
    urlHandle: string;
}

export class LocationCommonDto {
    city?: string;
    state?: string;
    country?: string;
    countryCode?: number;
    coOrdinates?: string[];
    status?: LOCATION_STATUS;
}

export class LocationInDto extends LocationCommonDto {
}

export class LocationOutDto extends LocationCommonDto {
    id: string;
    createdAt?: Date;
    lastModifiedAt?: Date;
}

export enum LOCATION_STATUS {
    IN_REVIEW = 'IN_REVIEW',
    PUBLISHED = 'PUBLISHED'
}

export enum ORGANIZATION_STATUS {
    ACTIVATED = 'ACTIVATED',
    DEACTIVATED = 'DEACTIVATED'
}

export enum SALARY_FREQUENCY {
    HOURLY = 'HOURLY',
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
    ANNUALLY = 'ANNUALLY'
}

export enum SALARY_FREQUENCY_DISPLAY {
    HOURLY = 'Hourly',
    DAILY = 'Daily',
    WEEKLY = 'Weekly',
    MONTHLY = 'Monthly',
    ANNUALLY = 'Annually'
}
