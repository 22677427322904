import {Component, Input, OnInit} from '@angular/core';
import {SECTION_LIST_DISPLAY} from "../../common/nav-menu-list";
import {WEBSITE_SECTION_TYPE} from "../../common/website-model/website-section.model";
import {LanguageAttributes} from "../../common/website-model/language.model";
import {WebsiteSectionSubCardComponent} from "../../website-section-sub-card/website-section-sub-card.component";
import {DateFormatter, DateUtil} from "../../../../common/util/date.util";
import {WebsiteSectionCardComponent} from "../../website-section-card/website-section-card.component";
import {LANGUAGE_PROFICIENCY_DISPLAY} from "../../../resume/common/resume-section-model/language.model";

@Component({
    selector: 'app-w-language',
    standalone: true,
    imports: [
        WebsiteSectionSubCardComponent,
        WebsiteSectionCardComponent
    ],
    templateUrl: './w-language.component.html',
    styleUrl: './w-language.component.scss'
})
export class WLanguageComponent implements OnInit {
    @Input() languageAttributes: LanguageAttributes | any;
    SECTION_LIST_DISPLAY = SECTION_LIST_DISPLAY;
    WEBSITE_SECTION_TYPE = WEBSITE_SECTION_TYPE;
    protected readonly DateUtil = DateUtil;
    protected readonly LANGUAGE_PROFICIENCY_DISPLAY = LANGUAGE_PROFICIENCY_DISPLAY;
    protected readonly DateFormatter = DateFormatter;

    constructor() {
    }

    ngOnInit(): void {

    }
}
