import {Component, OnInit} from '@angular/core';
import {CoverLetterService} from "./service/cover-letter.service";
import {SOCIAL_LINK_TYPE, SocialLink, SocialLinkAttributes} from "./model/cover-letter/social-link.model";
import {CoverLetterThemeModel} from "./model/cover-letter-theme.model";
import {NgClass, NgStyle} from "@angular/common";
import {CoverLetterOutDto} from "./model/cover-letter/cover-letter.model";
import {ROUTE} from "../../common/route-constants/route";
import {ActivatedRoute, Router} from "@angular/router";
import {PersonalInformationAttributes} from "./model/cover-letter/personal-information.model";
import {RecipientAttributes} from "./model/cover-letter/recipient.model";
import {SubjectAttributes} from "./model/cover-letter/subject.model";
import {GreetingParagraphAttributes} from "./model/cover-letter/greeting-paragraph.model";
import {BodyAttributes} from "./model/cover-letter/body.model";
import {ClosingMessageAttributes} from "./model/cover-letter/closing-message.model";
import {FormalSalutationAttributes} from "./model/cover-letter/formal-salutation.model";
import {CoverLetterThemeUtil} from "./cover-letter-theme-util/cover-letter-theme.util";
import {PoweredByFooterComponent} from "../../components/powered-by-footer/powered-by-footer.component";
import {LinkService} from "../../common/util/link.service";

@Component({
  selector: 'app-cover-letter',
  standalone: true,
  imports: [
    NgStyle,
    NgClass,
    PoweredByFooterComponent
  ],
  templateUrl: './cover-letter.component.html',
  styleUrl: './cover-letter.component.scss'
})
export class CoverLetterComponent implements OnInit {
  coverLetterOutDto: CoverLetterOutDto;
  personalInformationAttributes: PersonalInformationAttributes;
  socialLinkAttributes: SocialLinkAttributes;
  recipientAttributes: RecipientAttributes;
  subjectAttributes: SubjectAttributes;
  greetingParagraphAttributes: GreetingParagraphAttributes;
  bodyAttributes: BodyAttributes;
  closingMessageAttributes: ClosingMessageAttributes;
  formalSalutationAttributes: FormalSalutationAttributes;
  coverLetterTheme: CoverLetterThemeModel;
  SOCIAL_LINK_TYPE = SOCIAL_LINK_TYPE;
  editor = true;
  coverLetterUrlhandle: string;
  socialLinks: SocialLink[] = [];

  constructor(private coverLetterService: CoverLetterService,
              private route: ActivatedRoute,
              private coverLetterThemeUtil: CoverLetterThemeUtil,
              private router: Router,
              private linkService: LinkService) {
  }

  ngOnInit(): void {
    this.getCoverLetterId();
    this.setCoverLetterOutDto();
  }

  getCoverLetterId(): void {
    this.route.params.subscribe(params => {
      this.coverLetterUrlhandle = params[ROUTE.URL_HANDLE];
    });
  }

  openUrlInNewTab(url: string): void {
      this.linkService.openLinkInNewTab(url);
  }

  setCoverLetterOutDto(): void {
    this.coverLetterService.findPublicCoverLetterByUrlHandle(this.coverLetterUrlhandle).subscribe((coverLetterOutDto: CoverLetterOutDto) => {
        this.coverLetterOutDto = coverLetterOutDto;
        if (this.coverLetterOutDto) {
          this.prepareData(this.coverLetterOutDto);
          this.coverLetterTheme = this.coverLetterThemeUtil.getCoverLetterTheme(this.coverLetterOutDto.coverLetterThemeType);
        }
      },
      (error) => {
        this.router.navigate([ROUTE.PAGE_NOT_FOUND]);
      });
  }

  private prepareData(coverLetterOutDto: CoverLetterOutDto): void {
    this.personalInformationAttributes = coverLetterOutDto?.personalInformationAttributes;
    this.socialLinkAttributes = coverLetterOutDto?.socialLinkAttributes;
    this.recipientAttributes = coverLetterOutDto?.recipientAttributes;
    this.subjectAttributes = coverLetterOutDto?.subjectAttributes;
    this.greetingParagraphAttributes = coverLetterOutDto?.greetingParagraphAttributes;
    this.bodyAttributes = coverLetterOutDto?.bodyAttributes;
    this.closingMessageAttributes = coverLetterOutDto?.closingMessageAttributes;
    this.formalSalutationAttributes = coverLetterOutDto?.formalSalutationAttributes;
  }
}
