import {Component, Inject, OnInit} from '@angular/core';
import {environment} from "../../../../../../environments/environment";
import {ROUTE} from "../../../../../common/route-constants/route";
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {JobPostOutDto} from "../../../../service/public-api-job-post.service";

@Component({
    selector: 'tal-candidate-logged-in',
    standalone: true,
    imports: [
        MatDialogContent
    ],
    templateUrl: './candidate-logged-in.component.html',
    styleUrl: './candidate-logged-in.component.scss'
})
export class CandidateLoggedInComponent implements OnInit {

    jobPostTitle: string;
    jobPostOutDto: JobPostOutDto;
    placementDriveId: string;

    constructor(@Inject(MAT_DIALOG_DATA) private dialogData: any,
                private dialogRef: MatDialogRef<any>) {
    }

    ngOnInit(): void {
        this.setJobPostTitle();
        this.setJobPostOutDto();
        this.setPlacementDriveId();
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    navigateToLoginPage(): void {
        window.open(this.getAuthUrl(ROUTE.LOGIN), '_blank');
    }

    navigateToSignUpPage(): void {
        const registerRoute = `${ROUTE.CANDIDATE}${ROUTE.SLASH}${ROUTE.REGISTER}`;
        window.open(this.getAuthUrl(registerRoute), '_blank');
    }

    private getAuthUrl(route: string): string {
        const baseUrl = `${environment.standoutBaseAppUrl}${ROUTE.SLASH}${ROUTE.AUTH}${ROUTE.SLASH}${route}`;
        const jobPostParam = `jobPostId=${this.jobPostOutDto?.id}`;
        const placementDriveParam = this.placementDriveId ? `&placementDriveId=${this.placementDriveId}` : '';

        return `${baseUrl}?${jobPostParam}${placementDriveParam}`;
    }

    private setJobPostTitle(): void {
        this.jobPostTitle = this.dialogData?.jobPostOutDto?.jobTitle;
    }

    private setJobPostOutDto(): void {
        this.jobPostOutDto = this.dialogData?.jobPostOutDto;
    }

    private setPlacementDriveId(): void {
        this.placementDriveId = this.dialogData?.placementDriveId;
    }
}
