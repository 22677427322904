import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DatePipe, NgClass} from "@angular/common";
import {DateFormatter, DateUtil} from "../../util/date.util";
import {PublicApiEmployerHasDriveOutDto} from "../../../employer/company/drive/service/employer-has-drive.service";
import {LinkService} from "../../util/link.service";

@Component({
    selector: 'tal-job-card',
    standalone: true,
    imports: [
        NgClass,
        DatePipe,
    ],
    templateUrl: './job-card.component.html',
    styleUrl: './job-card.component.scss'
})
export class JobCardComponent implements OnInit, OnChanges {
    @Input() jobCardModel: JobCardModel;
    currentDate = new Date();
    protected readonly DateFormatter = DateFormatter;

    constructor(private linkService: LinkService) {
    }

    ngOnInit(): void {
        this.setDate();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.jobCardModel.isPlacementDriveJobExpired = changes?.['isPlacementDriveJobExpired']?.currentValue;
    }

    visitWebsite(): void {
        this.linkService.openLinkInNewTab(this.jobCardModel?.websiteLink);
    }

    private setDate(): void {
        const currentDate: string = this.currentDate.toISOString();
        this.jobCardModel.remainingDays = DateUtil.dateDifference(this.jobCardModel?.endDate, currentDate);
    }
}

export class JobCardModel {
    logo: string;
    title: string;
    companyLink: string;
    companyName?: string;
    location: string;
    level: string;
    placementDriveType?: string;
    department: string;
    jobType: string;
    workType?: string;
    remainingDays?: number;
    expireAt: string;
    expired: string;
    websiteLink: string;
    startDate: string;
    endDate: string;
    allJobCount: number;
    isViwJobSectionVisible: boolean;
    isPlacementDriveJobExpired: boolean;
    publicApiEmployerHasDriveOutDto: PublicApiEmployerHasDriveOutDto;
}
