<div class="p-10 h-screen overflow-auto outer-container"
     style="background-image: url('assets/resume/talenlio-background-image.png'); background-position: center; background-size: cover;">
    <div class="job-card-detail overflow-hidden box-shadow rounded-3xl zoom-in">
        <div class="job-card-banner w-full ex-object-fit op-top overflow-hidden">
            <img class="w-full h-full"
                 [src]="organizationOutDto?.careerSite?.coverPictureFileStore?.url || defaultCoverImage"
                 [alt]="organizationOutDto?.name">
        </div>
        <div class="heading-container flex gap-5">
            <div class="company-logo overflow-hidden">
                <img class="w-full h-full" [alt]="organizationOutDto?.name"
                     [src]="organizationOutDto?.careerSite?.logoFileStore?.url || companyLogo">
            </div>
            <div class="heading-text-container">
                <div class="company-heading-text">
                    <h1 class="heading-title text-2xl font-medium leading-6 mb-1">
                        {{ organizationOutDto?.name || '--' }}
                    </h1>
                </div>
                <div class="company-location-container flex items-center justify-between">
                    <div class="location-content">
                        @if (organizationOutDto?.address?.city) {
                            <div class="location-text flex items-start gap-2 mt-3">
                                <div class="ti ti-map-pin title-color"></div>
                                <div class="location-address title-color">
                                    {{ organizationOutDto?.address?.city }}
                                </div>
                            </div>
                        }
                        @if (organizationOutDto?.careerSite?.companySize) {
                            <div class="location-text flex items-center gap-1 mt-3">
                                <div class="ti ti-user title-color text-xl"></div>
                                <div class="team-size mt-0.5 title-color">
                                    {{ COMPANY_SIZE_DISPLAY[organizationOutDto?.careerSite?.companySize] }}
                                </div>
                            </div>
                        }
                    </div>
                    @if (organizationOutDto?.careerSite?.websiteLink) {
                        <div class="company-link company-link-text flex" (click)="openWebsite()">
                            Visit Website
                            <span class="ti ti-arrow-up-right arrow-icon"></span>
                        </div>
                    }
                </div>
            </div>
        </div>
        <div class="mt-5 company-tab">
            <mat-tab-group>
                <mat-tab label="Jobs">
                    <app-job [companyUrl]="companyUrl"></app-job>
                </mat-tab>
                <!--        TODO for now hiding drives tab will remove commented code once placement drive is ready-->
                <!--        <mat-tab label="Drives">-->
                <!--         <tal-drive [companyId]="companyOutDto?.id"></tal-drive>-->
                <!--        </mat-tab>-->
                <mat-tab label="About Us">
                    <app-about-us [companyUrl]="companyUrl"
                                  [organizationOutDto]="organizationOutDto">
                    </app-about-us>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div>
        </div>
    </div>
    <div class="py-8">
        <app-powered-by-footer></app-powered-by-footer>
    </div>
</div>
