import {Component, Input} from '@angular/core';
import {SECTION_LIST_DISPLAY} from "../../common/nav-menu-list";
import {DateFormatter, DateUtil} from '../../../../common/util/date.util';
import {WEBSITE_SECTION_TYPE} from "../../common/website-model/website-section.model";
import {EducationAttributes} from "../../common/website-model/education.model";
import {WebsiteSectionSubCardComponent} from "../../website-section-sub-card/website-section-sub-card.component";
import {WebsiteSectionCardComponent} from "../../website-section-card/website-section-card.component";

@Component({
  selector: 'app-w-education',
  standalone: true,
    imports: [
        WebsiteSectionSubCardComponent,
        WebsiteSectionCardComponent
    ],
  templateUrl: './w-education.component.html',
  styleUrl: './w-education.component.scss'
})
export class WEducationComponent {
  @Input() educationAttributes: EducationAttributes | any;
  DateFormatter = DateFormatter;
  DateUtil = DateUtil;
  startDate: Date;
  endDate: Date;
  SECTION_LIST_DISPLAY = SECTION_LIST_DISPLAY;
  WEBSITE_SECTION_TYPE = WEBSITE_SECTION_TYPE;

  constructor() {
  }

  getDateDifference(startDate: Date, endDate: Date): string {
    this.setStartEndDate(startDate, endDate);
    return this.DateUtil.getDifference(startDate, endDate);
  }

  setStartEndDate(startDate: Date, endDate: Date): void {
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
