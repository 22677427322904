import {Injectable} from '@angular/core';
import {HttpClientWrapperService} from "../../service/http-client-wrapper.service";
import {Observable} from "rxjs";
import {JobPostOutDto, OrganizationMinOutDto} from "./public-api-job-post.service";
import {SOCIAL_LINK} from "../enum/company.model";
import {FileStoreMinOutDto} from "../enum/file-store.model";

@Injectable({
    providedIn: 'root'
})
export class PublicApiOrganizationService {
    private publicUrl = '/public';
    private organizationsUrl = '/organizations';

    constructor(private httpClientWrapperService: HttpClientWrapperService) {
    }

    find(organizationId: string): Observable<OrganizationOutDto> {
        return this.httpClientWrapperService.get<OrganizationOutDto>(`${this.getUrl()}/${organizationId}`);
    }

    findByUrlHandle(urlHandle: string): Observable<OrganizationOutDto> {
        return this.httpClientWrapperService.get<OrganizationOutDto>(`${this.getUrl()}/${urlHandle}`);
    }

    update(employerId: string, organizationInDto: OrganizationInDto): Observable<OrganizationOutDto> {
        return this.httpClientWrapperService.put<OrganizationOutDto>(`${this.getUrl()}/${employerId}`, organizationInDto);
    }

    patch(employerId: string, organizationPatchInDto: OrganizationPatchInDto): Observable<OrganizationOutDto> {
        return this.httpClientWrapperService.patch<OrganizationOutDto>(`${this.getUrl()}/${employerId}`, organizationPatchInDto);
    }

    private getUrl(): string {
        return `${this.publicUrl}${this.organizationsUrl}`;
    }
}

export class Address {
    street: string;
    city: string;
    state: string;
    country: string;
    countryCode: number;
    zipCode: number;
    latitude: number;
    longitude: number;
    formattedAddress: String;
}

export class OrganizationCommonDto {
    name?: string;
    domain?: string;
    websiteLink?: string;
    urlHandle?: string;
    featured?: boolean;
    address?: Address;
    organizationStatus?: ORGANIZATION_STATUS;
    organizationType?: ORGANIZATION_TYPE;
    enableMatchedCandidate?: boolean;
    talenlioNetwork?: boolean;
    referredBy?: string;
    careerSite?: CareerSite;
    createdFrom?: USER_TYPE;
    defaultCurrency?: CURRENCY;
}

export class CareerSite {
    id?: string;
    headline?: string;
    summary?: string;
    founded?: string;
    primaryColor?: string;
    secondaryColor?: string;
    textColor?: string;
    subTextColor?: string;
    perksAndBenefit?: string;
    instagramLink?: string;
    linkedinLink?: string;
    xLink?: string;
    youtubeLink?: string;
    websiteLink?: string;
    facebookLink?: string;
    companySize?: string;
    industry?: string;
    logoFileStore?: FileStoreMinOutDto;
    coverPictureFileStore?: FileStoreMinOutDto;
    faviconFileStore?: FileStoreMinOutDto;
    location?: Location;
    careerSiteHasContactPerson?: CareerSiteHasContactPerson;
}

export class CareerSiteHasContactPerson {
    role?: string;
    workEmailAddress?: string;
    workContactNumber?: string;
    socialLink?: string;
    socialLinkType?: SOCIAL_LINK;
    profilePictureAttachmentId?: string;
    contactPerson: ContactPerson;
}

export class ContactPerson {
    name: string;
    emailAddress: string;
    address: string;
    contactNumber: string;
}

export class OrganizationInDto extends OrganizationCommonDto {
    companyId: string;
    contactPersonInDto: ContactPersonInDto;
}

export enum USER_TYPE {
    CANDIDATE = 'CANDIDATE',
    EMPLOYER = 'EMPLOYER',
    UNIVERSITY = 'UNIVERSITY'
}

export class OrganizationOutDto extends OrganizationCommonDto {
    id: string;
    contactPersonOutDto: ContactPersonOutDto;
    brandingFileStore: FileStoreMinOutDto;
    memberOutDtos: MemberOutDto[];
    jobPostOutDtos: JobPostOutDto[];
}

export class OrganizationPatchInDto {
    domain: string;
}

export class MemberOutDto {
    id: string;
    name: string;
    address: AddressModel;
    emailAddress: string;
    contactNumber: string;
    memberType: MEMBER_TYPE;
    memberStatus: MEMBER_STATUS;
    organizationMinOutDto: OrganizationMinOutDto;
    profileImageUrl: string;
    createdAt: string;
    lastModifiedAt: string;
}

export class MemberInDto {
    name?: string;
    address?: AddressModel;
    emailAddress?: string;
    contactNumber?: string;
    memberType?: MEMBER_TYPE;
    memberStatus?: MEMBER_STATUS;
    profileImageUrl?: string;
    contactPersonInDto?: ContactPersonInDto;
}

export class AddressModel {
    locality?: string;
    postal_code?: string;
    administrative_area_level_1?: string;
    country?: string;
    route?: string;
    street_number?: string;
}

export class ContactPersonCommonDto {
    name: string;
    emailAddress: string;
    contactNumber: string;
    contactNumberType?: string;
    address: string;
}

export class ContactPersonInDto extends ContactPersonCommonDto {
}

export class ContactPersonOutDto extends ContactPersonCommonDto {
    id: string;
}

export enum CURRENCY {
    INR,
    EUR,
    USD,
    AED,
    SAR
}

export enum ORGANIZATION_STATUS {
    ACTIVATED = 'ACTIVATED',
    DEACTIVATED = 'DEACTIVATED'
}

export enum ORGANIZATION_TYPE {
    EMPLOYER = 'EMPLOYER',
    UNIVERSITY = 'UNIVERSITY'
}

export enum MEMBER_TYPE {
    OWNER = 'OWNER',
    MEMBER = 'MEMBER',
    ADMIN = 'ADMIN'
}

export enum MEMBER_STATUS {
    PENDING_REQUEST = 'PENDING_REQUEST',
    ACTIVATED = 'ACTIVATED',
    DEACTIVATED = 'DEACTIVATED'
}
