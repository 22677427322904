import {Component} from '@angular/core';
import {talenlioWebsite} from '../../common/website-constant/talenlio-website';
import {LinkService} from '../../../../common/util/link.service';

@Component({
    selector: 'app-w-footer',
    standalone: true,
    imports: [],
    templateUrl: './w-footer.component.html',
    styleUrl: './w-footer.component.scss'
})
export class WFooterComponent {

    constructor(private linkService: LinkService) {
    }

    navigateToTalenlioWebsite(): void {
        this.linkService.openLinkInNewTab(talenlioWebsite.url);
    }
}
