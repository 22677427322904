import {Component, OnInit} from '@angular/core';
import {CONSTANTS} from '../../common/constant/constant';
import {PoweredByFooterComponent} from '../powered-by-footer/powered-by-footer.component';
import {LinkService} from '../../common/util/link.service';

@Component({
    selector: 'tal-page-not-found',
    standalone: true,
    imports: [
        PoweredByFooterComponent
    ],
    templateUrl: './page-not-found.component.html',
    styleUrl: './page-not-found.component.scss'
})
export class PageNotFoundComponent implements OnInit {

    constructor(private linkService: LinkService) {
    }

    ngOnInit(): void {
        this.navigateInThreeSecond();
    }

    navigateToTalenlio(): void {
        this.linkService.openLinkInNewTab(CONSTANTS.TALENLIO_LINK);
    }

    private navigateInThreeSecond(): void {
        setTimeout(() => {
            this.navigateToTalenlio();
        }, 3000);
    }
}
